














import TitleBar from "@/components/TitleBar/index.vue";
import ProrgramSearch from "@/components/ProgramSearch/index.vue";
import Vue from "vue";
import MyPrograms from "@/components/MyPrograms/index.vue";
export default Vue.extend({
  name: "AppHomeController",
  components: {
    MyPrograms,
    TitleBar,
    ProrgramSearch,
  },
});
