
























import Vue from "vue";
import ProgramTable from "@/components/ProgramTable/index.vue";

export default Vue.extend({
  name: "MyPrograms",
  components: { ProgramTable },
  props: {
    limit: {
      type: Number,
      required: false,
      default: 0,
    },
    title: {
      type: String,
      required: false,
      default: "My Programs",
    },
  },
  methods: {
    /**
     * View the selected program
     * @param program
     */
    onprogramClick(programID: string) {
      this.$router.push({
        name: "app-programs-attendance",
        params: { id: programID },
      });
    },
  },
});
