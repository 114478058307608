









































































import Vue from "vue";
import { ProgramsManager } from "@/services/ProgramsManager";
import Program from "@/Classes/Program";
import { mdiChevronRight } from "@mdi/js";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default Vue.extend({
  name: "ProgramTable",
  data() {
    return {
      loadingPrograms: false,
      hasPrograms: false,
      programs: [] as Program[],
      error: [] as string[],
      rightArrowIcon: mdiChevronRight,
    };
  },
  computed: {
    /**
     * Returns TRUE to display loading programs skeleton.
     *
     * @return {boolean}
     */
    showLoading(): boolean {
      return this.loadingPrograms;
    },
  },
  created() {
    this.load();
  },
  methods: {
    /**
     * Handle the "click" event when clicking on a program.
     *
     */
    onProgramClick(programID: string): void {
      this.$emit("program-selected", programID);
    },
    load() {
      this.loadingPrograms = true;
      const programsManager = new ProgramsManager();
      programsManager
        .getProgramsForCurrentUser()
        .then((programs) => {
          this.hasPrograms = programs.length > 0;
          this.programs = programs;
        })
        .catch((response) => {
          this.error = ApiResponseHelper.getErrorMessagesFromResponse(response);
        })
        .finally(() => {
          this.loadingPrograms = false;
        });
    },

    formatSessionText(program: Program): string {
      const session = program.attributes.sessions?.[0];
      if (
        session?.attributes?.title !== "" &&
        session?.attributes?.startDateTimeUtc
      ) {
        // Format date
        const date = new Date(session.attributes.startDateTimeUtc);
        const day = date.getDate();
        const month = date.toLocaleString("default", { month: "long" });
        const formattedDate = `${day}${
          day > 3 ? "th" : day === 3 ? "rd" : day === 2 ? "nd" : "st"
        } ${month}`;
        return `${session.attributes.title} - ${formattedDate}`;
      }
      return "";
    },

    showSessionText(program: Program): boolean {
      const session = program.attributes.sessions?.[0];
      if (
        session?.attributes?.title !== "" &&
        session?.attributes?.startDateTimeUtc
      ) {
        return true;
      }
      return false;
    },
  },
});
