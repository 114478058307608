

















































import Vue from "vue";
import { mdiSend } from "@mdi/js";
import { ProgramsManager } from "@/services/ProgramsManager";
import Program from "@/Classes/Program";
import ApiResponseHelper from "@/lib/api/ApiResponseHelper";

export default Vue.extend({
  name: "ProgramSearch",
  props: {
    title: {
      type: String,
      required: false,
      default: "Program Search",
    },
  },
  data() {
    return {
      mdiSend: mdiSend,
      programCode: "" as string,
      scanDialog: false as boolean,
      destroyed: true as boolean,
      error: "" as string,
      loading: false as boolean,
      searchLoading: false as boolean,
      searchErrors: [] as string[],
    };
  },
  methods: {
    searchProgram() {
      // check if the length is 7 otherwise show error message
      if (this.programCode.length !== 7) {
        this.searchErrors = ["Please enter a valid program code"];
        return;
      }
      this.searchLoading = true;
      const programsManager = new ProgramsManager();
      programsManager
        .getProgramByPRC(this.programCode)
        .then((program: Program) => {
          this.searchLoading = false;

          this.$router.push({
            name: "app-programs-attendance",
            params: { id: program.id },
          });
        })
        .catch((error) => {
          this.searchLoading = false;
          this.searchErrors = ApiResponseHelper.getErrorMessagesFromResponse(
            error
          );
          this.programCode = "";
        });
    },

    formattedProgramCode(value: string) {
      this.programCode = value
        .toUpperCase()
        .replace(/[^A-Z0-9]/g, "")
        .replace(/([A-Z0-9]{3})([A-Z0-9]{0,3})/, "$1-$2");
    },
  },
});
